<template>
  <div id="advertise-parkingLot">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>广告管理</el-breadcrumb-item>
      <el-breadcrumb-item>区域列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="table-container">
      <div class="table-filtrate">
        <ul>
          <li>
            <span class="label">输入关键字:</span>
            <el-input
              placeholder="请输入内容"
              v-model="filtrate.keyword"
              @keyup.enter.native="getList(1)"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="getList(1)"
              ></el-button>
            </el-input>
          </li>
          <li>
            <el-button type="primary" size="medium" @click="getList(1)"
              >查询</el-button
            >
          </li>
          <li>
            <el-button class="resetBtn" size="medium" @click="reset"
              >重置</el-button
            >
          </li>
        </ul>
      </div>
      <el-table
        :data="table.tableData"
        style="width: 100%"
        v-loading="table.loading"
      >
        <el-table-column
          prop="parkingLotName"
          label="停车场名称"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="adNum"
          label="广告数量"
          align="center"
        ></el-table-column>
        <el-table-column prop="waitReview" label="待审数量" align="center">
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="goToDetail(scope.row.parkingLotId)"
              v-if="buttonList.includes('advertise-parkingLot-detail')"
              >查看详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          layout="sizes, total, prev, pager, next, jumper"
          background
          :total="table.total"
          :page-size.sync="table.pageSize"
          :current-page.sync="table.page"
          @current-change="getList"
          @size-change="getList(1)"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      filtrate: {
        keyword: "",
      },
      table: {
        tableData: [],
        total: 0,
        page: 1,
        pageSize: 10,
        loading: false,
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    reset() {
      this.filtrate.keyword = "";
      this.getList(1);
    },
    goToDetail(parkingLotId) {
      this.$router.push({
        name: "advertise-parkingLot-detail",
        params: { parkingLotId },
      });
    },
    async getList(page = this.table.page) {
      this.table.page = page;
      try {
        this.table.loading = true;
        let res = await this.$http.post("/ad/parking/list", {
          pageNum: page,
          pageSize: this.table.pageSize,
          keyword: this.filtrate.keyword,
        });
        if (res.code === 0) {
          for (let item of res.data.list) {
            item.lng_lat = `${item.lng}, ${item.lat}`;
          }
          this.table.tableData = res.data.list;
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.table.loading = false;
      }
    },
    area_create() {
      this.$refs.actionDialog.show = true;
    },
    area_edit(row) {
      this.$refs.actionDialog.areaId = row.areaId;
      for (let key in this.$refs.actionDialog.form) {
        this.$refs.actionDialog.form[key] = row[key];
      }
      this.$refs.actionDialog.show = true;
    },
    area_delete(areaId) {
      this.$confirm("此操作将永久删除该区域, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        let res = await this.$http.delete(`/ad/area/${areaId}/delete`);
        if (res.code === 0) {
          this.$message.success("删除成功");
          this.getList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="scss">
#advertise-parkingLot {
}
</style>
